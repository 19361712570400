import React, { Component } from 'react';
import Obfuscate from 'react-obfuscate';
import classes from './Menu.module.css';
import './react-slick.css';
import Slider from "react-slick";
import Img_afl1 from './assets/images/afl1.png';
import Img_afl2 from './assets/images/afl2.png';
import Img_afl3 from './assets/images/afl3.png';
import Img_afl4 from './assets/images/afl4.png';
import Img_afl5 from './assets/images/afl5.png';
import Img_afl6 from './assets/images/afl6.png';

const episodeList = [
    {
        id : 'VPWON_1281267',
        epno: 1,
        title: 'Verzamelen',
        img: Img_afl1,
        url: 'https://www.npostart.nl/de-toren/16-06-2019/VPWON_1281267',
        timestamp: '1560714300',
        date: '16 juni'
    },
    {
        id : 'VPWON_1281268',
        epno: 2,
        title: 'Herbarium',
        img: Img_afl2,
        url: 'https://www.npostart.nl/de-toren/23-06-2019/VPWON_1281268',
        timestamp: '1561319100',
        date: '23 juni',
    },
    {
        id : 'VPWON_1281269',
        epno: 3,
        title: 'Schepping',
        img: Img_afl3,
        url: 'https://www.npostart.nl/de-toren/30-06-2019/VPWON_1281269',
        timestamp: '1561923900',
        date: '30 juni',
    },
    {
        id : 'VPWON_1281270',
        epno: 4,
        title: 'Diepzee',
        img: Img_afl4,
        url: 'https://www.npostart.nl/de-toren/07-07-2019/VPWON_1281270',
        timestamp: '1562528700',
        date: '7 juli',
    },
    {
        id : 'VPWON_1281271',
        epno: 5,
        title: 'Dodo',
        img: Img_afl5,
        url: 'https://www.npostart.nl/de-toren/14-07-2019/VPWON_1281271',
        timestamp: '1563133546',
        date: '14 juli',
    },
    {
        id : 'VPWON_1281272',
        epno: 6,
        title: 'Dubois',
        img: Img_afl6,
        url: 'https://www.npostart.nl/de-toren/21-07-2019/VPWON_1281272',
        timestamp: '1563738300',
        date: '21 juli'
    },
];

class Menu extends Component {
    onSwipe() {
        if (this.slider) {
          this.slider.innerSlider.clickable = true;
        }
      }
    
    componentDidUpdate() {
        if( !this.props.show ) {
            const thisDiv = document.getElementsByClassName(classes.Menu)[0];
            const timedScroll = setTimeout(
                () => {
                    thisDiv.scrollTop = 0;
                    clearTimeout(timedScroll);
                }, 1100
            )
        }
    }

    render () {

    const sliderRef = slider => {this.slider = slider;};

    let theseClasses = [classes.Menu];
    if (this.props.show) {
           theseClasses.push(classes['Open']);
    }

    const isIE = /*@cc_on!@*/false || !!document.documentMode;

    let sliderSettings = [];
    if (this.props.windowWidth < 641 && !isIE) {
    sliderSettings = {
            dots: false,
            infinite: true,
            centerMode: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            onSwipe: this.onSwipe.bind(this),
            swipeToSlide: true,
          };
    } else if (this.props.windowWidth >= 641 && this.props.windowWidth < 1025 && !isIE ) {
        sliderSettings = {
            dots: false,
            infinite: true,
            centerMode: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: true,
            swipe: false,
        };
    } else  {
        sliderSettings = {
            dots: false,
            infinite: true,
            centerMode: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            swipe: false,
        };
    }

    const episodes = episodeList.map(episode => (
        (
          <div key={episode.id} className={classes.Wrapper}>
                <a href={episode.url} target="_blank" rel="noopener noreferrer">
                    <img src={episode.img} alt={episode.title} />
                    <h3>{episode.title}</h3>
                    {episode.timestamp < Math.floor(Date.now()/1000) ? <p>Bekijk de aflevering</p> : <p>Beschikbaar vanaf {episode.date}</p>}
                </a>
            </div>
        )));
 
    return (
        <div className={theseClasses.join(' ')}>
            <div className={classes.Tvmenu}>
                <h2>De Toren op televisie</h2>
                <Slider {...sliderSettings} ref={sliderRef}>{episodes}</Slider>
            </div>
            <div className={classes.Tvmenu}>
                <h2>Over</h2>
                <p>DE TOREN is een TV-serie van zes afleveringen en vier verhalen op deze website. DE TOREN gaat over natuurhistorie. Over wie we zijn en waar we vandaan komen, over de helden die de wereld afreizen op zoek naar antwoorden, over de miljoenen natuurhistorische <a href="https://topstukken.naturalis.nl" target="_blank" rel="noopener noreferrer">schatten</a> die al zijn verzameld en over hoe weinig we nog maar weten. Op het platform zie je nooit eerder vertoonde zeedieren, leef je mee met een geniale wetenschapper die geen erkenning kreeg, overweeg je of de mens goddelijke scheppingskracht moet krijgen én ontdek je hoe een geheime liefde misschien wel tot de meest romantische natuurschat ooit heeft geleid. In de TV-serie maak je elke aflevering een nieuwe ontdekkingsreis naar een uithoek van onze raadselachtige planeet, op zoek naar antwoord op die ene vraag: wat is leven?</p>
                <p>Voor het maken van het televisieprogramma DE TOREN hebben we veel gebruik gemaakt van de enorme verzameling natuurhistorische schatten van <a href="https://www.naturalis.nl" target="_blank" rel="noopener noreferrer">Naturalis</a> in Leiden, de collectiebeheerders en onderzoekers.</p>
                <p>Contact:  <Obfuscate email="info@ntr.nl" /></p>
                <h2>Over ‘Een eeuwig lachende bloementuin’</h2>
                <p>Het online verhaal 'Een eeuwig lachende bloementuin' beschrijft aan het einde op een geromantiseerde manier de reden waarom het En Tibi herbarium tot stand zou kunnen zijn gekomen. Hierover bestaat geen absolute zekerheid. Wetenschappers Anastasia Stefanaki en Tinde van Andel hebben na jarenlang onderzoek wel ontdekt wíe de geheimzinnige maker van het En Tibi herbarium was. Op 26 juni is een artikel van hen gepubliceerd op <a href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0217779" target="_blank" rel="noopener noreferrer">https://journals.plos.org</a> waarin zij beschrijven hoe ze hun ontdekking deden.</p>

                <h2>Colofon</h2>
                <p><a href="https://bureauvoorlichting.nl/" target="_blank" rel="noopener noreferrer">Bureau Voorlichting</a> is verantwoordelijk voor het concept, de vormgeving, research, productie en het ontwikkelen en schrijven van alle verhalen.</p>
                <ul>
                    <li>Samenstelling en productieleiding - Siebren Hodes</li>
                    <li>Creatieve input - Bas Zwartepoorte</li>
                    <li>Scenario's - Jochem Salemink</li>
                    <li>Beeldredactie en sounddesign - Jerom Galema</li>
                    <li>Productie - Else-Marie Rombouts en Joost Engelberts</li>
                </ul>
                <p>Eindredactie &amp; Projectmanagement - Karin Wijkmans, <a href="https://www.ntr.nl" target="_blank" rel="noopener noreferrer">NTR</a></p>
                <ul>
                    <li>Interactief Concept &amp; Development - Martijn van Tol &amp; Wouter Vroege, <a href="https://slices.co" target="_blank" rel="noopener noreferrer">Slices Tinker</a></li>
                    <li>Vormgeving - Floris Deerenberg, <a href="http://astudiocalledquest.com" target="_blank" rel="noopener noreferrer">a studio called quest</a></li>
                    <li>Stem Tussen aap en mens – <a href="https://www.simonheijmans.com" target="_blank" rel="noopener noreferrer">Simon Heijmans</a></li>
                    <li>Stem Duik in het onbekende – <a href="https://www.naturalis.nl/en/katja-peijnenburg" target="_blank" rel="noopener noreferrer">Katja Peijnenburg</a></li>
                    <li>Illustraties Het (ont)scheppingsverhaal - <a href="https://ted-parker.com" target="_blank" rel="noopener noreferrer">Ted Parker</a></li>
                    <li>Titel typografie - <a href="https://guidodeboer.info" target="_blank" rel="noopener noreferrer">Guido de Boer</a></li>
                    <li>Development homepage - Piet van Hulst &amp; Roel Jorna, NTR Digitaal</li>
                </ul>
                <p>Veel wetenschappers hebben bijgedragen aan de totstandkoming van de TV-afleveringen:</p>
                <h3>Verzamelen</h3>
                <ul>
                    <li><a href="https://www.naturalis.nl/en/over-ons/management-and-trustees" target="_blank" rel="noopener noreferrer">Edwin van Huis</a></li>
                    <li><a href="https://www.naturalis.nl/en/vincent-kalkman" target="_blank" rel="noopener noreferrer">Vincent Kalkman</a></li>
                    <li><a href="https://www.naturalis.nl/en/bhutan-biodiversity-project" target="_blank" rel="noopener noreferrer">Kees Klein</a></li>
                    <li><a href="https://www.researchgate.net/profile/Choki_Gyeltshen" target="_blank" rel="noopener noreferrer">Choki Gyeltshen</a></li>
                </ul> 
                <h3>Herbarium</h3>
                <ul>
                    <li><a href="https://www.naturalis.nl/en/sylvia-mota-oliveira" target="_blank" rel="noopener noreferrer">Sylvia Mota de Oliveira</a></li>
                    <li><a href="https://www.naturalis.nl/en/hans-ter-steege" target="_blank" rel="noopener noreferrer">Hans ter Steege</a></li>
                    <li><a href="https://www.naturalis.nl/en/tinde-van-andel" target="_blank" rel="noopener noreferrer">Tinde van Andel</a></li>
                    <li><a href="https://www.naturalis.nl/en/anastasia-stefanaki" target="_blank" rel="noopener noreferrer">Anastasia Stefanaki</a></li>
                </ul>
                <h3>Schepping</h3>
                <ul>
                    <li>Kommer Tanis – Visser &amp; Verzamelaar</li>
                    <li><a href="http://kornfeldt.com/about-me/" target="_blank" rel="noopener noreferrer">Torill Kornfeldt</a></li>
                    <li><a href="http://palaeogenetics.com/LD" target="_blank" rel="noopener noreferrer">Love Dalén</a></li>
                    <li><a href="https://nioo.knaw.nl/nl/employees/liesbeth-bakker" target="_blank" rel="noopener noreferrer">Liesbeth Bakker</a></li>
                </ul>
                <h3>Diepzee</h3>
                <ul>
                    <li><a href="https://www.naturalis.nl/en/katja-peijnenburg" target="_blank" rel="noopener noreferrer">Katja Peijnenburg</a></li>
                    <li><a href="https://www.naturalis.nl/en/nicole-voogd" target="_blank" rel="noopener noreferrer">Nicole de Voogd</a></li>
                </ul>
                <h3>Dodo</h3>
                <ul>
                    <li><a href="https://www.maastrichtuniversity.nl/nl/leon.claessens" target="_blank" rel="noopener noreferrer">Leon Claessens</a></li>
                    <li><a href="http://julianhume.co.uk" target="_blank" rel="noopener noreferrer">Julian Hume/</a></li>
                    <li><a href="http://uom.academia.edu/anwarjanoo" target="_blank" rel="noopener noreferrer">Anwar Janoo </a></li>
                </ul> 
                <h3>Dubois</h3>
                <ul>
                    <li><a href="https://www.naturalis.nl/en/jose-joordens" target="_blank" rel="noopener noreferrer">José Joordens</a></li>
                    <li><a href="https://palbers.home.xs4all.nl/welcome.htm" target="_blank" rel="noopener noreferrer">Paul Albers </a></li>
                    <li><a href="https://www.naturalis.nl/en/frank-wesselingh" target="_blank" rel="noopener noreferrer">Frank Wesselingh</a></li>
                    <li><a href="https://www.eurekalert.org/multimedia/pub/83782.php?from=284198" target="_blank" rel="noopener noreferrer">Stephen Munro</a></li>
                    <li><a href="https://www.researchgate.net/profile/Shinatria_Adhityatama" target="_blank" rel="noopener noreferrer">Shinatria Adhityatama</a></li>
                    <li>NEAT/NASA maakte de beelden van planetoïde Dubois</li>
                </ul>
            </div>
        </div>);   
    } 
};

export default Menu