import React from 'react';

import classes from './Bloementuin.module.css';

const bloementuin = (props) => {
    const gotoUrl = () => {
        window.location.href= 'https://detoren.ntr.nl/content/' + props.path + '/index.html';
    }

    let theseClasses = [classes.Bloementuin];
    if (props.contentSize) {
        if (props.opened) {
           theseClasses.push(classes['Open']);
        } else {
            theseClasses.push(classes[props.contentSize]);
        }
    }

    return(
        <div className={theseClasses.join(' ')}  onClick={props.clicked}>
            <h1>Een eeuwig lachende bloementuin</h1>
            <p>Het mysterieuze verhaal van een kunstschat die een raadselachtige liefdesbrief blijkt te zijn.</p>
            <button onClick={gotoUrl}>Bemin</button>
        </div>
    )
}

export default bloementuin;