import React from 'react';

import classes from './Aap.module.css';

const aap= (props) => {
    const gotoUrl = () => {
        window.location.href= 'https://detoren.ntr.nl/content/' + props.path + '/index.html';
    }

    let theseClasses = [classes.Aap];
    if (props.contentSize) {
        if (props.opened) {
           theseClasses.push(classes['Open']);
        } else {
            theseClasses.push(classes[props.contentSize]);
        }
    }

    if  (props.path === 'eeneeuwiglachendebloementuin') {
        theseClasses.push(classes['bloementuinOpen']);
    }  

    return(
        <div className={theseClasses.join(' ')} onClick={props.clicked}>
            <h1>Tussen aap en mens</h1>
            <p>Ervaar hoe we de ontdekker van de missing link zijn vergeten.</p>
            <button onClick={gotoUrl}>Zoek</button>
        </div>
    )
}

export default aap;