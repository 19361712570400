import React from 'react';

import classes from './Duik.module.css';

const duik = (props) => {
    const gotoUrl = () => {
        window.location.href= 'https://detoren.ntr.nl/content/' + props.path + '/index.html';
    }

    let theseClasses = [classes.Duik];
    if (props.contentSize) {
        if (props.opened) {
           theseClasses.push(classes['Open']);
        } else {
            theseClasses.push(classes[props.contentSize]);
        }
    }
    if (props.path === 'hetontscheppingsverhaal') {
        theseClasses.push(classes['ontscheppingOpen']);
    } 

    return(
        <div className={theseClasses.join(' ')} onClick={props.clicked}>
            <h1>Duik in het onbekende</h1>
            <p>Ontmoet wezens waarvan je het bestaan niet had kunnen vermoeden.</p>
            <button onClick={gotoUrl}>Duik</button>
        </div>
    )
}

export default duik;