import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

import Landing from '../containers/Landing/Landing';
import Layout from '../hoc/Layout/Layout';

class App extends Component {
 
    render() {
      const routes = (
        <Switch>
          <Route path={`${process.env.PUBLIC_URL}/hetontscheppingsverhaal`} render={() => <Landing path="hetontscheppingsverhaal" />} />
          <Route path={`${process.env.PUBLIC_URL}/duikinhetonbekende`} render={() => <Landing path="duikinhetonbekende" />} />
          <Route path={`${process.env.PUBLIC_URL}/tussenaapenmens`} render={() => <Landing path="tussenaapenmens" />} />
          <Route path={`${process.env.PUBLIC_URL}/eeneeuwiglachendebloementuin`} render={() => <Landing path="eeneeuwiglachendebloementuin" />} />   
          <Route path={`${process.env.PUBLIC_URL}/menu`} render={() => <Landing path="menu" />} />        
          <Route path={`${process.env.PUBLIC_URL}/`} exact render={() => <Landing path="home" />} />
          <Redirect to={`${process.env.PUBLIC_URL}/`} />
        </Switch>
      )
  
      return (
        <Layout>
          {routes}
        </Layout>

      );
    }
  }
  
  export default withRouter(App);