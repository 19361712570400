import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import windowSize from 'react-window-size';

import Logline from '../../components/Logline/Logline';
import Menu from '../../components/Navigation/Menu/Menu';
import Ontschepping from '../../components/Content/Ontschepping/Ontschepping';
import Duik from '../../components/Content/Duik/Duik';
import Aap from '../../components/Content/Aap/Aap';
import Bloementuin from '../../components/Content/Bloementuin/Bloementuin';

class Landing extends Component {
    state = {
        showLogline : false,
    }

    componentDidMount () {
        if (this.props.path === 'home') {
            this.interval = setInterval(this.slideInLoglineHandler, 1000);
        }
    }

    navigationHandler = (path) => {
        this.props.history.replace(process.env.PUBLIC_URL + '/' + path);
        this.closeLoglineHandler();
    }

    slideInLoglineHandler = () => {
        this.setState({showLogline: false});
        clearInterval(this.interval);
    }

    closeLoglineHandler = () => {
        this.setState({showLogline: false});
    }

    componentWillUnmount () {
        clearInterval(this.interval);
    }

    render () {
        let contentSize = '';
        if ( this.props.path === 'menu' ) {
            contentSize = 'Smallest'
        }

        if ( this.state.showLogline === true ) {
            contentSize = 'Small'
        }
        if (this.props.path !== 'home' && this.props.path !== 'menu'  && this.state.showLogline === false) {
            contentSize = 'Medium'
        }

        return (
            <div>
                <Logline clicked={this.closeLoglineHandler} show={this.state.showLogline && this.props.path !== 'menu'} /> 
                <Menu show={this.props.path === 'menu'} windowWidth={this.props.windowWidth} /> 
                <Ontschepping clicked={() => this.navigationHandler("hetontscheppingsverhaal")} opened={this.props.path === 'hetontscheppingsverhaal'} path={this.props.path} contentSize={contentSize} />
                <Duik clicked={() => this.navigationHandler("duikinhetonbekende")} opened={this.props.path === 'duikinhetonbekende'}  path={this.props.path} contentSize={contentSize} />
                <Aap clicked={() => this.navigationHandler("tussenaapenmens")} opened={this.props.path === 'tussenaapenmens'}  path={this.props.path} contentSize={contentSize} />
                <Bloementuin clicked={() => this.navigationHandler("eeneeuwiglachendebloementuin")} opened={this.props.path === 'eeneeuwiglachendebloementuin'} path={this.props.path} contentSize={contentSize} />
            </div>
        );
        }
}

export default withRouter(windowSize(Landing));