import React from 'react';

import classes from './Ontschepping.module.css';

const ontschepping = (props) => {
    const gotoUrl = () => {
        window.location.href= 'https://detoren.ntr.nl/content/' + props.path + '/index.html';
    }

    let theseClasses = [classes.Ontschepping];
    if (props.contentSize) {
        if (props.opened) {
           theseClasses.push(classes['Open']);
        } else {
            theseClasses.push(classes[props.contentSize]);
        }
    }

    return(
        <div className={theseClasses.join(' ')} onClick={props.clicked}>
            <h1>Het <span className={[classes.white]}>(ont)</span>scheppingsverhaal</h1>
            <p>Hoe de mens ontdekt dat Gods schepping ongedaan kan worden gemaakt en steeds verdergaat in de poging zelf de wereld te scheppen.</p>
            <button onClick={gotoUrl}>Ontschep</button>
        </div>
    )
}

export default ontschepping;