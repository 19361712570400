import React, { Component, Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import classes from './Layout.module.css';

class Layout extends Component {

navigationHandler = (path) => {
    this.props.history.replace(process.env.PUBLIC_URL + '/' + path);
}

  render() {
    return(
      <Fragment>
        <Switch>
          <Route path={`${process.env.PUBLIC_URL}/menu`} render={() => <Toolbar menuOpen={true} clicked={() => this.navigationHandler("")}  clickedHome={() => this.navigationHandler("")} />} />        
          <Route path={`${process.env.PUBLIC_URL}/`} render={() => <Toolbar menuOpen={false} clicked={() => this.navigationHandler("menu")} clickedHome={() => this.navigationHandler("")} />} />
        </Switch>
      <main className={classes.Content}>
        {this.props.children}
      </main>
    </Fragment>
    )
  }
} 
 
export default withRouter(Layout);
