import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';

const app = (
        <BrowserRouter>
            <App />
        </BrowserRouter>
)

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.register();
