import React from 'react';
import classes from './Logline.module.css';

const logline = (props) => {
    let theseClasses = [classes.Logline];
    if (props.show) {
           theseClasses.push(classes['Open']);
    }

    return (
        <div className={theseClasses.join(' ')} onClick={props.clicked}>
            <p>Vier verhalen uit onze natuur-historie.</p>
        <button onClick={props.clicked} className={classes.Sluit}>sluit</button>
        </div>);    
};

export default logline