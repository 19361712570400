import React from 'react';
import classes from './Toolbar.module.css';
import Logo from './assets/images/ntr-logo-zwart.png'

const toolbar = (props) => {
    let theseClasses = [classes.Menu];
    if (props.menuOpen) {
           theseClasses.push(classes['Open']);
    }

    return (
        <header className={classes.Toolbar}>
        <div className={classes.SideItem}><a href="https://www.ntr.nl"><img src={Logo} alt="NTR" /></a></div>
        <h1 onClick={props.clickedHome}>De Toren</h1>
        <div className={classes.SideItem}><button onClick={props.clicked} className={theseClasses.join(' ')} /></div>
        </header>);    
};

export default toolbar